import { szrApiV2 } from "@/axios.js"

szrApiV2.defaults.timeout = 600000

export default {
    async getListOfTasks(cmuitaccount) {
        try {
            let response = await szrApiV2.get(`/data/${cmuitaccount}/tasks`)
            return response["data"]["data"]
        } catch (error) {
            console.log("error ", error["message"])
            return false
        }
    },
    async getListOfTasksByDate(start, end, options) {
        try {
            let queryString = `?option=${options}`
            let response = await szrApiV2.get(`/data/tasks/start/${start}/end/${end}${queryString}`)
            return response["data"]["data"]
        } catch (error) {
            console.log("error ", error["message"])
            return false
        }
    },
    async getTaskListOfUser(cmuitaccount, start, end) {
        try {
            let queryString = ""
            if (cmuitaccount.length > 0) {
                queryString = `?cmuitaccount=${cmuitaccount}&option=all`

            }
            let response = await szrApiV2.get(`/data/tasks/start/${start}/end/${end}${queryString}`)
            return response["data"]["data"]
        } catch (error) {
            console.log("error ", error["message"])
            return false
        }
    },
}